<template>
  <div class="comment-component">
    <div v-if="user == null">
      no comment
    </div>

    <div v-else class="comment-box">
      <div class="flex py-2">
        <div class="flex-none">
          <div class="flex">
            <Image
                v-tooltip.top="user.nickname"
                :src="user.image"
                class="h-9 w-9 rounded-full object-cover mr-3"/>
          </div>
        </div>
        <div class="flex-1 w-96">
          <div class="form-control">
              <textarea
                  v-model="commentItem.body"
                  style="width:100%"
                  class="bg-transparent border-b border-[#778289] pl-1 focus:outline-none focus:border-b-[#e5e5d8] input-sm overflow-scroll overflow-x-auto h-[25px] min-h-[25px] max-h-60 scrollbar-thin scrollbar-thumb-gray-900 scrollbar-track-gray-100 scroller text-sm"
                  placeholder="Сэтгэгдэлээ бичнэ үү..."/>
          </div>

          <div class="flex justify-between mx-1">
            <div class="text-[#778289] hover:text-[#e5e5d8] mt-[5px]">
              <div class="dropdown dropdown-start">
                <div tabindex="0">
                  <i class="uil uil-grin text-xl leading-3" @click="toggleEmoji"></i>
                </div>
                <OverlayPanel ref="emoji">
                  <VuemojiPicker @emojiClick="handleEmojiClick"/>
                </OverlayPanel>
              </div>
            </div>

            <div class="mr-[-10px]">
              <Button type="button" label="Илгээх" icon="pi pi-send" class="p-button-text p-button-sm" iconPos="right"
                      :loading="senderLoading" href="javascript:void(0)" @click="postComment"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="page == 1 && isLoadingEpisode" class="media-loader-wrap">
      <ProgressSpinner/>
    </div>

    <ScrollPanel v-else class="ep-custombar text-[#a3abb7]">
      <div v-for="c in comments" :key="c.index" class="flex py-3 comment">
        <div class="flex-none">
          <div class="flex mr-4">
            <Image
                :src="c.user.image"
                class="h-8 w-8 rounded-full object-cover"/>
          </div>
        </div>
        <div class="flex-1 c-body">
          <div class="font-bold text-sm c-body-header">
            <span v-if="user && user.id == c.user.id" class="text-white c-name">{{ user.nickname }}</span>
            <span v-else class="text-white c-name">{{ c.user.nickname }}</span>
            <span class="c-date">{{ formatDate(c.created_at) }}</span>
          </div>
          <p class="c-content">{{ c.comment }}</p>
          <div class="flex items-stretch c-actions">
            <a :class="`hover:text-white hover:no-underline p-0 m-0 c-like-btn ${c.action == 1 ? 'active':''}`"
               @click="setAction(c, c.action == 1 ? 0 : 1)">
              <i class="pi pi-thumbs-up"></i>
              <span>{{ c.likes }}</span>
            </a>

            <a :class="`hover:text-white hover:no-underline ml-3 c-like-btn ${c.action == 2 ? 'active':''}`"
               @click="setAction(c, c.action == 2 ? 0 : 2)">
              <i class="pi pi-thumbs-down"></i>
              <span>{{ c.dislikes }}</span>
            </a>
          </div>
        </div>
      </div>
    </ScrollPanel>

    <div v-if="hasMore" class="flex justify-center">
      <ProgressSpinner v-if="isLoadingMore" style="width:50px;height:50px" strokeWidth="3" animationDuration="1s"/>
      <Button v-else class="p-button-outlined p-button-sm" @click="getMore">
        <span>Цааш</span>
        <i class="pi pi-angle-double-down"></i>
      </Button>
    </div>
  </div>
</template>

<script>
import Image from "./Image.vue";
import {mapGetters} from "vuex";
import {VuemojiPicker} from 'vuemoji-picker'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/mn.json'

TimeAgo.addDefaultLocale(en);

export default {
  components: {
    Image,
    VuemojiPicker
  },

  data() {
    return {
      isLoading: true,
      isLoadingMore: false,
      senderLoading: false,
      page: 1,
      hasMore: true,
      isDone: false,
      commentItem: {
        body: "",
        type: "ep",
        user_id: null,
        parent_id: null,
        reply_id: null,
      },
      search: "",
    };
  },

  computed: {
    ...mapGetters(['user', 'comments', 'episode', 'isLoadingEpisode'])
  },

  created() {
    this.$store.dispatch('getComments', {vm: this})
  },

  // watch: {
  //   ep: {
  //     deep: true,
  //     handler() {
  //       this.getComments({vm: this, episode: this.episode});
  //     }
  //   }
  // },

  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },

  methods: {
    append(emoji) {
      this.commentItem.body += emoji;
    },

    toggleEmoji(event) {
      this.$refs.emoji.toggle(event);
    },

    handleEmojiClick(detail) {
      this.commentItem.body += detail.unicode;
    },

    formatDate(dt) {
      const timeAgo = new TimeAgo('en-US')
      return timeAgo.format(new Date(dt))
    },

    getMore() {
      this.isLoadingMore = true;
      this.$store.dispatch('getComments', {vm: this})
    },

    postComment() {
      if (this.commentItem.body.toString().trim() == "") {
        this.$toast.error("Сэтгэгдэлээ бичнэ үү!");
      }

      this.$store.dispatch('addComment', {vm: this});
    },

    setAction(item, action) {
      if (this.user == null) {
        this.$toast.error("Нэвтэрч орсны дараа уг үйлдлийг хийх боломжтой");
        return;
      }

      this.$store.dispatch('setAction', {vm: this, item, action})


      // api.get(`/api/m/comment/action/${item.id}/${this.$user.id}/${action}`)
      //     .then(({data}) => {
      //       item.action = action;
      //       console.log(data);
      //     });
    },

  },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-scrollpanel) {
  p {
    padding: .5rem 0;
    line-height: 1.2;
    margin: 0;
  }

  &.ep-custombar {
    width: 100%;
    height: 100%;
    overflow-x: hidden !important;

    .p-scrollpanel-bar {
      width: 4px;
    }
  }
}

.p-button {
  margin-right: .5rem;
}

.p-buttonset {
  .p-button {
    margin-right: 0;
  }
}

.sizes {
  .button {
    margin-bottom: .5rem;
    display: block;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 640px) {
  .p-button {
    margin-bottom: .5rem;

    &:not(.p-button-icon-only) {
      display: flex;
      width: 100%;
    }
  }

  .p-buttonset {
    .p-button {
      margin-bottom: 0;
    }
  }
}
</style>
